<template>
  <Dialog v-model:visible="display" header='点击"开始圈地"后，打开进行圈地操作（双击可结束圈地）'
          @hide="$emit('close')" :modal="true">
    <div class="content">
      <div id="map" style="width:100%;height: 100%"></div>
      <div class="search">
        <InputText id="search" v-model="searchValue" @keydown.enter="searchName" placeholder="输入名称以后搜索"/>
        <ul v-if="searchEnter">
          <li v-for="item in searchResult" @click="toAddress(item)">
            {{ item.name }}
            <div>{{ item.district }}</div>
          </li>
        </ul>
      </div>
      <div class="select">
        <Button @click="drawing" class="p-mr-2">开始圈地</Button>
        <Button @click="createPoint" class="p-mr-2">开始打点</Button>
        <Button @click="submit" class="p-mr-2">保存</Button>
      </div>

      <div id="weather" class="weather" v-if="weather"
           :style="{height:hasData?'160px':'0',marginBottom:show?'0px':'-100px'}">
        <div style="width: 100%;" @click="show=!show">
          <i v-if="show" class="pi pi-angle-down"></i>
          <i v-else class="pi pi-angle-up"></i>
        </div>
        <!--        <div>year 年份 <p>{{ weather[0] }}</p></div>-->
        <div><p><span>{{ weather[1] ? weather[1] : null }}</span>°C</p>年平均气温</div>
        <div><p><span>{{ weather[2] ? weather[2] : null }}</span>°C</p>年平均最高气温</div>
        <div><p><span>{{ weather[3] ? weather[3] : null }}</span>°C</p>年平均最低气温</div>
        <div><p><span>{{ weather[4] ? weather[4] : null }}</span>mm</p>年度降雨量</div>
        <div><p><span>{{ weather[5] ? weather[5] : null }}</span>天</p>年度下雨天数</div>
        <div><p><span>{{ weather[6] ? weather[6] : null }}</span>天</p>年度下雪天数</div>
        <div><p><span>{{ weather[7] ? weather[7] : null }}</span>天</p>年度雷阵雨天数</div>
        <div><p><span>{{ weather[8] ? weather[8] : null }}</span>天</p>年度起雾天数</div>
        <div><p><span>{{ weather[9] ? weather[9] : null }}</span>天</p>年度龙卷风天数</div>
        <div><p><span>{{ weather[10] ? weather[10] : null }}</span>天</p>年度冰雹天数</div>
      </div>
      <div class="blockUI" v-if="over&&!hasData" @click.stop="clickUI"></div>
      <div class="loadingdiv" v-if="over&&!hasData">加载天气数据中...</div>
    </div>
  </Dialog>
</template>


<script>
let map, mouseTool;
export default {
  name: 'addOrUpdateRole',
  data() {
    return {
      display: false,
      map: null,
      path: null,
      mouseTool: null,
      polyEditor: null,
      polygon: null,
      autocomplete: null,
      searchValue: null,
      searchResult: [],
      searchEnter: false,
      hasDraw: false,
      onDraw: false,
      overlays: [],
      update: false,
      updateing: false,
      area: null,
      weather: null,
      hasData: false,
      over: false,
      show: true,
      center: null,

    };
  },
  methods: {

    init(item, info) {
      this.display = true;
      if (item === 0) {
        this.update = true;
        if (info) {
          if (info.path && info.area) {
            this.path = info.path;
            this.area = info.area;
          } else {
            this.center = info.center;
          }
        }
      } else {
        this.update = false;
      }

      setTimeout(() => {
        this.createMap();
      }, 400);
    },
    beforeUnmount() {
      if (map) {
        map.destroy();
        map = null;
      }
    },
    createMap() {

      if (map) {
        map.destroy();
      }
      map = null;

      map = new AMap.Map('map', {
        resizeEnable: true,
        layers: [
          new AMap.TileLayer.RoadNet(),
          new AMap.TileLayer.Satellite(),
        ],
        zoom: 8,
      });
      this.autocomplete = new AMap.Autocomplete({
        input: 'search',
      });
      mouseTool = new AMap.MouseTool(map);
      if (this.update) {
        if (this.center && !this.path) {

          new AMap.Marker({
            map: map,
            position: this.center,
          });
          map.setZoomAndCenter(15, this.center);
          this.createPoint();
        } else {
          let list = [];
          this.path.forEach(item => {
            list.push([item.R, item.Q]);
          });
          this.polygon = new AMap.Polygon({
            path: list,
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff',
            fillOpacity: 0.4,
          });
          map.add(this.polygon);

          let text = new AMap.Text({
            position: new AMap.LngLat(this.path[0].R, this.path[0].Q),
            text: '区域面积' + this.area + '平方米',
            offset: new AMap.Pixel(-20, -20),
          });
          map.add(text);
          // 缩放地图到合适的视野级别
          map.setFitView([this.polygon]);
          const _this = this;
          this.polyEditor = new AMap.PolyEditor(map, this.polygon);
          this.polyEditor.on('end', function(event) {
            _this.path = event.target.De.path;
            _this.com();
          });
        }
      }

    },
    createPoint() {
      this.path = null;
      this.area = null;
      this.center = null;
      map.clearMap();
      if (this.polyEditor) {
        this.polyEditor.close();
      }
      const _this = this;
      map.on('click', this.onclick);
    },
    onclick(event) {
      console.log(event);
      map.clearMap();

      let marker = new AMap.Marker({
        map: map,
        position: event.lnglat,
      });
      map.add(marker);
      map.setZoomAndCenter(15, event.lnglat);
    },
    removePoint() {

      const _this = this;
      map.off('click', function(event) {
        _this.onclick(event);
      });
      let center = map.getCenter();
      map.getCity(function(res) {
        let info = {
          center: center,
          city: res,
        };
        _this.$emit('mapInfo', info);
        _this.display = false;
      });
    },
    searchName() {
      this.searchEnter = true;
      let _this = this;
      this.autocomplete.search(this.searchValue, function(status, result) {
        _this.searchResult = result.tips;
      });
    },
    clickUI() {
      console.log('稍等');
    },

    toAddress(item) {
      // console.log(item);
      this.searchEnter = false;
      this.searchValue = null;
      map.setZoomAndCenter(15, item.location);
    },
    drawCallback(e) {
      this.path = JSON.parse(JSON.stringify(e['obj'].De.path));
      console.log(this.path);
      this.onDraw = false;
      this.hasDraw = true;
      this.com();
    },
    getWeather(res) {
      this.over = true;
      this.hasData = false;
      return new Promise((resolve, reject) => {
        this.$http('plan/getWeather', 'get', {
          province: res.province,
          city: res.city,
          type: 'year',
        }, (result) => {
          if (result) {
            let s = result.data.substring(1, result.data.length - 1);
            this.weather = s.split(',');
          }
          this.over = false;
          this.hasData = true;
          resolve();
        }, () => {
          this.over = false;
          reject();
        });
      });

    },

    drawing() {
      let _this = this;
      this.onDraw = true;
      this.path = null;
      this.area = null;
      this.center = null;
      map.clearMap();
      map.off('click',
          _this.onclick,
      );
      mouseTool.on('draw', function(e) {
        _this.drawCallback(e);
      });
      mouseTool.polygon({
        fillColor: '#00b0ff',
        strokeColor: '#80d8ff',
      });
    },

    clearDraw(e) {
      e.stopPropagation();
      let allOverlays = map.getAllOverlays();
      map.remove(allOverlays);
    },
    com() {
      // let _this = this;
      //let allOverlays = map.getAllOverlays();

      // if (allOverlays && allOverlays.length > 0) {
      //   allOverlays.forEach(item => {
      //     if (item.CLASS_NAME === 'AMap.Text') {
      //       map.remove(item);
      //     }
      //   });
      // }
      this.area = Math.round(AMap.GeometryUtil.ringArea(this.path));

      let text = new AMap.Text({
        position: new AMap.LngLat(this.path[0].R, this.path[0].Q),
        text: '区域面积' + Number(this.area * 0.0015).toFixed(2) + '亩',
        offset: new AMap.Pixel(-20, -20),
      });
      text.setMap(map);
      map.setFitView();

    },
    beginUpdate() {
      this.updateing = true;
      this.polyEditor.open();
    },
    overUpdate() {
      let _this = this;
      this.updateing = false;
      if (this.polyEditor) {
        this.polyEditor.close();
      }
      // map.getCity(function (res) {
      //   _this.getWeather(res).then(() => {
      //   })
      // })
    },
    submit() {
      let center = map.getCenter();
      let _this = this;
      if (this.polyEditor) {
        this.polyEditor.close();
      }
      map.getCity(function(res) {
        //  let keys = ['year', 't', 'tmax', 'tmin', 'pp', 'v', 'ra', 'sn', 'ts', 'fg', 'tn', 'gr'];
        // let weatherData = {}
        //
        // keys.forEach((key, index) => {
        //   weatherData[key] = Number(_this.weather[index])
        // })

        // console.log(res);
        let info = {
          path: _this.path,
          area: _this.area * 0.0015,
          center: center,
          city: res,
          // weather: weatherData
        };
        _this.$emit('mapInfo', info);
        _this.display = false;
      });
    },
  },
};
</script>

<style scoped>
.content {
  width: 50vw;
  height: 50vh;
  position: relative;
  overflow: hidden;
}

.search {
  position: absolute;
  width: 200px;
  height: 50px;
  top: 1rem;
  left: 1rem;
}

.search input {
  width: 100%;
}

.search ul {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 300px;
  overflow: scroll;
}

.search li {
  line-height: 20px;
  background: #ffffff;
  padding: 10px 10px 0 10px;
  color: #666666;
  cursor: pointer;
}

.search li div {
  color: #999999;
  font-size: 12px;
}

.select {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 50px;
}

.weather {
  background: #ffffff;
  color: #333333;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 0px;
  transition: all .5s;
  position: absolute;
  bottom: -10px;
  z-index: 999;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 15px;
}

.weather div {
  width: 20%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 0px;
  color: #999999;
}

.weather div p {
  width: 100%;
}

.weather div p span {
  font-weight: 500;
  font-size: 15px;
  color: #333333;
}

.blockUI {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #111111;
  opacity: 0.3;
  top: 0;
  left: 0;
  z-index: 999;
}

.loadingdiv {
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  z-index: 999;
  position: absolute;
  font-size: 20px;
  display: flex;
  width: 200px;
  text-align: center;
  font-weight: 600;
  justify-content: center;
  top: calc(50% - 20px);
  left: calc(50% - 100px);

}

</style>
